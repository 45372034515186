import {Component, inject, input, signal, WritableSignal} from '@angular/core';
import {NgOptimizedImage} from '@angular/common';
import {RouterLink} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {HeaderMenuService} from '@core/services/header-menu.service';
import {faAngleDown, faAngleRight, faAngleUp} from '@fortawesome/free-solid-svg-icons';
import {RouterContextDirective} from '@core/directives/router-context.directive';
import {ClickTrackingDirective} from '@core/directives/click-tracking.directive';

export interface MenuStructure {
  sub_links: { links: any[], _uid: string }[]
}

@Component({
  selector: 'app-header-menu-sub-menu',
  templateUrl: './header-menu-sub-menu.component.html',
  styleUrls: ['./header-menu-sub-menu.component.scss'],
  imports: [NgOptimizedImage, RouterLink, FontAwesomeModule, RouterContextDirective, ClickTrackingDirective]
})
export class HeaderMenuSubMenuComponent {
  private headerMenuService = inject(HeaderMenuService);

  faAngleRight = faAngleRight;

  menuStructure = input<MenuStructure | undefined>()

  pathWithLabels = input<string>('');

  activeSubMenuItem: WritableSignal<MenuStructure | undefined> = signal(undefined);
  mouseEnterTimeOut: any;

  isExternalURL(path: string): boolean {
    if (!path) {
      return false;
    }
    return path?.indexOf('http') === 0;
  }

  subMenuItemMouseEnter(subMenu: any) {
    if (this.mouseEnterTimeOut) {
      clearTimeout(this.mouseEnterTimeOut);
    }
    this.mouseEnterTimeOut = setTimeout(() => {
      this.setActiveSubMenuItem(subMenu);
    }, 300);
  }

  subMenuItemMouseLeave() {
    if (this.mouseEnterTimeOut) {
      clearTimeout(this.mouseEnterTimeOut);
    }
  }

  subMenuItemClick(event: any, subMenu: any) {
    event.preventDefault();
    if (this.activeSubMenuItem === subMenu) {
      this.setActiveSubMenuItem();
      return;
    }
    this.setActiveSubMenuItem(subMenu);
  }

  closeMenu() {
    this.headerMenuService.setActiveMenu(null);
  }

  setActiveSubMenuItem(subMenu?: MenuStructure) {
    this.activeSubMenuItem.set(subMenu);
  }

  getMenuType(menuStructure: any) {
    if (menuStructure.component === 'AlgoliaMenu') {
      return 'AlgoliaMenu';
    } else if (menuStructure.sub_links?.length > 0) {
      return 'subMenu';
    } else {
      return 'menu';
    }
  }

  getAdditionalContext(item: any, extraContext: any = {}) {
    return {
      name: item.Name,
      menu_navigation_path: this.getPath(item),
      ...extraContext
    }
  }

  getPath(item: any) {
    return this.pathWithLabels() + ' > ' + item.Name;
  }

  protected readonly faAngleUp = faAngleUp;
  protected readonly faAngleDown = faAngleDown;
}
