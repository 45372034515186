<div class="container">
  <h5 class="font-serif">Leder du mon efter...</h5>
  <div class="content-container">
    <div class="opening-hours-container">
      <p class="info-title font-serif">Kundeservice åbningstider:</p>
      <p class="opening-hours">Mandag - Fredag: <span>08:00 - 17:30</span></p>
      <p class="opening-hours">Lørdag: <span>10:00 - 16:00</span></p>
      <p class="info-title font-serif mt-4">Erhverv åbningstider:</p>
      <p class="opening-hours">Mandag - Fredag: <span>08:00 - 16:00</span></p>
    </div>
    <div class="links">
      <p class="info-title font-serif">Kontakt</p>
      <a [appRouterContext]="'footer'" [routerLink]="'/info/kontakt-os'">Kontakt os</a>
      <a [appRouterContext]="'footer'" [routerLink]="'/info/b2b-salg'">B2B Salg</a>
      <a [appRouterContext]="'footer'" [routerLink]="'/info/engros-salg'">Engros salg</a>
    </div>
    <div class="links">
      <p class="info-title font-serif">Genveje</p>
      <a [appRouterContext]="'footer'" [routerLink]="'/info/topkategorier'">Topkategorier</a>
      <a href="https://blog.supervin.dk/" target="_blank">Blog</a>
      <a [appRouterContext]="'footer'" [routerLink]="'/info/konkurrence'">Konkurrencer</a>
      <a [appRouterContext]="'footer'" [routerLink]="'/black-friday'">Black Friday 2025</a>
    </div>
    <div class="links">
      <p class="info-title font-serif">Information</p>
      <a [appRouterContext]="'footer'" [routerLink]="'/info/hvem-er-vi'">Hvem er vi?</a>
      <a [appRouterContext]="'footer'" [routerLink]="'/info/aabningstider'">Vores butikker</a>
      <a [appRouterContext]="'footer'" [routerLink]="'/info/leveringsinformation'">Levering</a>
      <a [appRouterContext]="'footer'" [routerLink]="'/info/betingelser'">Handelsbetingelser</a>
      <a href="/info/betingelser#Withdrawal">Fortrydelsesret</a>
      <a [appRouterContext]="'footer'" [routerLink]="'/persondatapolitik'">Persondatapolitik</a>
      <a [appRouterContext]="'footer'" [routerLink]="'/cookiepolitik'">Cookiepolitik</a>
    </div>
  </div>
</div>
