import {Component, computed, inject, output} from '@angular/core';
import {UserService} from '@core/services/user.service';
import {QuicksearchComponent} from '../quicksearch/quicksearch.component';
import {RouterModule} from '@angular/router';

import {HeaderMiddleVoucherComponent} from './components/header-middle-voucher/header-middle-voucher.component';
import {CartSmallComponent} from '../cart-small/cart-small.component';
import {NgOptimizedImage} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StoryblokService} from '@core/services/storyblok.service';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import {RouterContextDirective} from '@core/directives/router-context.directive';
import {TrackEventService} from '@core/services/track-event.service';
import {UserIconComponent} from '@core/svg-icons/user-icon.component';
import {CheckMarkerIconComponent} from '@core/svg-icons/check-marker-icon.component';
import {RoutingService} from '@core/services/routing.service';
import {SuitcaseIconComponent} from '@core/svg-icons/suitcase-icon.component';


@Component({
  selector: 'app-header-middle',
  templateUrl: './header-middle.component.html',
  styleUrls: ['./header-middle.component.scss'],
  imports: [QuicksearchComponent, RouterModule, HeaderMiddleVoucherComponent, CartSmallComponent, NgOptimizedImage, FontAwesomeModule, RouterContextDirective, UserIconComponent, CheckMarkerIconComponent, SuitcaseIconComponent]
})
export class HeaderMiddleComponent {
  storyblokService = inject(StoryblokService);
  private modalService = inject(NgbModal);
  private userService = inject(UserService);
  private trackEventService = inject(TrackEventService);
  private routingService = inject(RoutingService);


  userSignal = this.userService.userSignal;

  customerFirstname = this.userService.customerFirstnameSignal;

  isUserUniverse = computed(() => this.routingService.routerSignal()?.includes('kundeportal'));
  isTastingEvent = computed(() => this.routingService.routerSignal()?.startsWith('event'));

  readonly toggleMenu = output();

  openCustomerUniverseModal() {
    if (this.userService.currentUser()?.id) {
      this.openWelcomeModal();
      return;
    }
    this.openLoginModal();
  }

  openLoginModal() {
    this.trackEventService.trackClickEvent('login_modal_open');
    import('../../../../modals/login-modal/login-modal.component').then(comp => {
      this.modalService.open(comp.LoginModalComponent);
    })
  }

  openWelcomeModal() {
    this.trackEventService.trackClickEvent('welcome_modal_open');
    import('../../../../modals/welcome-modal/welcome-modal.component').then(comp => {
      this.modalService.open(comp.WelcomeModalComponent);
    })
  }

  protected readonly faBars = faBars;
}
