import {Component, HostListener, inject, input, Signal, viewChild} from '@angular/core';

import {HeaderMenuService} from '@core/services/header-menu.service';
import {map} from 'rxjs/operators';
import {GridService} from '@core/services/grid.service';
import {faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {toSignal} from '@angular/core/rxjs-interop';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {RouterLink} from '@angular/router';
import {
  MenuAnnouncekitComponent
} from '@layout/components/general/header/components/header-bottom/menu-announcekit/menu-announcekit.component';
import {RouterContextDirective} from '@core/directives/router-context.directive';
import {ClickTrackingDirective} from '@core/directives/click-tracking.directive';

@Component({
  selector: 'li[app-header-menu-item]',
  templateUrl: './header-menu-item.component.html',
  styleUrls: ['./header-menu-item.component.scss'],
  imports: [FaIconComponent, RouterLink, MenuAnnouncekitComponent, RouterContextDirective, ClickTrackingDirective]
})
export class HeaderMenuItemComponent {
  private headerMenuService = inject(HeaderMenuService);
  private gridService = inject(GridService);

  showSubMenu: Signal<boolean | undefined>;

  readonly announceKit = viewChild<MenuAnnouncekitComponent>('announcekitComponent');

  readonly name = input<string>();
  readonly hasSubMenu = input<boolean>();
  readonly url = input<string>();

  showAnnounceKit = input<boolean>(false);

  constructor() {


    this.showSubMenu = toSignal(this.headerMenuService.activeMenu$.pipe(
      map((activeMenu) => {
        return activeMenu === this.name();
      })
    ));

  }

  menuItemClick() {
    const name = this.name();
    if (!name) {
      return;
    }
    this.headerMenuService.toggleMenu(name);
  }

  announceKitClick(event: MouseEvent) {
    this.announceKit()?.announceKitClick(event);
  }

  @HostListener('mouseenter', ['$event'])
  mouseenter(event: any) {
    setTimeout(() => {
      const name = this.name();
      if (!name) {
        return;
      }
      this.headerMenuService.setActiveMenu(name);
    })
  }

  @HostListener('mouseleave', ['$event'])
  mouseleave(event: any) {
    this.headerMenuService.setActiveMenu(null);
  }

  closeMenu() {
    if (this.gridService.isLarge) {
      this.headerMenuService.setActiveMenu(null);
    }
  }

  getAdditionalContext(name?: string) {
    if (!name) {
      return;
    }
    return {
      menu_navigation_path: name
    }
  }

  protected readonly faAngleDown = faAngleDown;
}
