@if (url() && !showAnnounceKit()) {
  <a
    [additionalContext]="getAdditionalContext(name())"
    class="menu-item"
    [class.sub-menu-active]="showSubMenu() && hasSubMenu()"
    [appRouterContext]="'headerMenu'"
    [routerLink]="url()"
    (click)="closeMenu()"
  >
    <span>{{ name() }}
      @if (hasSubMenu()) {
        <fa-icon [icon]="faAngleDown"></fa-icon>
      }
    </span>
    @if (hasSubMenu()) {
      <div class="sub-menu">
        <ng-content></ng-content>
      </div>
    }
  </a>
}
@if (url() && showAnnounceKit()) {
  <a
    class="menu-item"
  >
    <span
      (click)="announceKitClick($event)"
      [appClickTracking]="'announcekitClick'">
      {{ name() }}
      <app-menu-announcekit #announcekitComponent/>
    </span>
    @if (hasSubMenu()) {
      <div class="sub-menu">
        <ng-content></ng-content>
      </div>
    }
  </a>
}
@if (!url()) {
  <div
    class="menu-item"
    [additionalContext]="{name: name() ?? ''}"
    (click)="closeMenu()"
    [class.sub-menu-active]="showSubMenu() && hasSubMenu()"
    [appClickTracking]="'headerMenuClick'"
  >
    <span (click)="menuItemClick()">{{ name() }}
      @if (hasSubMenu()) {
        <fa-icon [icon]="faAngleDown"></fa-icon>
      }
    </span>
    @if (hasSubMenu()) {
      <div class="sub-menu">
        <ng-content></ng-content>
      </div>
    }
  </div>
}
