import {Component, inject, signal} from '@angular/core';
import {FormValidationClassDirective} from '@core/directives/form-validation-class.directive';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {NgbAlert, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HsFormsService} from '@core/services/hs-forms.service';

@Component({
  selector: 'app-footer-newsletter',
  templateUrl: './footer-newsletter.component.html',
  styleUrl: './footer-newsletter.component.scss',
  imports: [
    FormValidationClassDirective,
    FormsModule,
    ReactiveFormsModule,
    NgbAlert
  ]
})
export class FooterNewsletterComponent {

  modal = inject(NgbModal);
  hsFormsService = inject(HsFormsService);

  showSuccessMessage = signal(false);
  showErrorMessage = signal(false);
  loading = signal(false);

  newsletterForm = new FormGroup({
    firstname: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  submitForm() {
    if (this.newsletterForm.valid && !this.loading()) {
      this.loading.set(true);
      this.hsFormsService.submitHubspotFormEvent('FooterSubscribeToNewsletters', JSON.stringify(this.newsletterForm.value)).then(() => {
        this.showSuccessMessage.set(true);
        this.loading.set(false);
      }).catch(() => {
        this.showErrorMessage.set(true);
        this.loading.set(false);
      });
    }
  }

  openPersonDataPolicy() {
    import('../../../../modals/basic-page-modal/basic-page-modal.component').then(comp => {
      const ref = this.modal.open(comp.BasicPageModalComponent);
      (ref.componentInstance).path = 'persondatapolitik';
    })
  }

}
