import {Component, inject, OnInit, PLATFORM_ID, Signal} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {CartService, CartWrapper} from '@core/services/cart.service';
import {tap} from 'rxjs/operators';
import {CartSmallPreviewComponent} from './components/cart-small-preview/cart-small-preview.component';
import {AsyncPipe, CurrencyPipe, isPlatformServer} from '@angular/common';
import {PricePipe} from '@core/pipes/price.pipe';
import {TrackEventService} from '@core/services/track-event.service';
import {CartIconComponent} from '@core/svg-icons/cart-icon.component';

@Component({
  selector: 'app-cart-small',
  templateUrl: './cart-small.component.html',
  styleUrls: ['./cart-small.component.scss'],
  imports: [AsyncPipe, CurrencyPipe, CartSmallPreviewComponent, PricePipe, CartIconComponent]
})
export class CartSmallComponent implements OnInit {
  private cartService = inject(CartService);
  private platformId = inject(PLATFORM_ID);
  private trackEventService = inject(TrackEventService);


  cartSignal: Signal<CartWrapper>;
  isPreviewVisibleObservable: Observable<boolean>;

  isPreviewVisible$ = new BehaviorSubject(false);

  ngOnInit(): void {
    this.cartSignal = this.cartService.cartSignal;
    this.isPreviewVisibleObservable = this.isPreviewVisible$.asObservable().pipe(
      tap(data => {
        if (isPlatformServer(this.platformId)) {
          return;
        }
        if (data) {
          document.body.classList.add('stop-scrolling');
          document.body.classList.add('replace-scrollbar');
        } else {
          document.body.classList.remove('stop-scrolling');
          document.body.classList.remove('replace-scrollbar');
        }
        if (data) {
          this.trackEventService.trackClickEvent('small_cart_open');
        }
      })
    );
  }
}
