@if (breadcrumbs$|async; as breadcrumbs) {
  @if (breadcrumbs.length !== 0) {
    <div [class.light]="(breadcrumbStyle$|async) === 'light'"
         class="breadcrumbs container">
      <div class="item-list">
        <span>Du er her: </span>
        <ol class="breadcrumb-list">
          @for (br of breadcrumbs; track br.path; let last = $last; let first = $first) {
            @if (getFormattedUrl(br.path); as formattedUrl) {
              <li [class.first]="first" [class.last]="last">
                <a
                  [appRouterContext]="'breadcrumb'"
                  [fragment]="formattedUrl.hash"
                  [queryParams]="formattedUrl.search"
                  [routerLink]="formattedUrl.pathname"
                >{{ br.label }}</a>
              </li>
            }
          }
        </ol>
      </div>
    </div>
  }
}
