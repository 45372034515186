<div class="container">
  <nav class="navbar navbar-expand-lg navbar-dark">
    <div class="navbar-collapse collapse" [ngClass]="[isCollapsed() ? 'hide' : 'show']">
      @if (menuStoryBlok()) {
        <ul class="navbar-nav mx-auto">
          @for (menuStructure of menuStoryBlok().content.Menu; track menuStructure.Name) {
            @if (getMenuType(menuStructure) === 'AlgoliaMenu') {
              <li
                [hasSubMenu]="true"
                [name]="menuStructure.Name"
                app-header-menu-item
                class="nav-item nav-background">
                @if (config(); as conf) {
                  <app-instantsearch [config]="conf">
                    <app-instantsearch-hierarchical-menu
                      [highlights]="menuStructure.highlights"
                      [attributes]="[menuStructure.algolia_menu + '.lvl0', menuStructure.algolia_menu + '.lvl1', menuStructure.algolia_menu + '.lvl2']"
                      [limit]="15"
                    ></app-instantsearch-hierarchical-menu>
                  </app-instantsearch>
                }
              </li>
            }
            @if (getMenuType(menuStructure) === 'menu') {
              <li
                data-rr-shared="true"
                [class]="getMenuClassName(menuStructure)"
                [hasSubMenu]="false"
                [name]="menuStructure.Name"
                [showAnnounceKit]="menuStructure.show_announcekit"
                [url]="menuStructure.Link?.url"
                app-header-menu-item
                class="nav-item nav-background">
              </li>
            }
            @if (getMenuType(menuStructure) === 'subMenu') {
              <li
                data-rr-shared="true"
                [class.dropdown-left]="menuStructure.dropdown_direction === 'left'"
                [class]="getMenuClassName(menuStructure)"
                [hasSubMenu]="true"
                [name]="menuStructure.Name"
                app-header-menu-item>
                <app-header-menu-sub-menu [menuStructure]="menuStructure"
                                          [pathWithLabels]="menuStructure.Name"></app-header-menu-sub-menu>
              </li>
            }
          }
        </ul>
      }
    </div>
  </nav>
</div>
