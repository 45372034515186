import {Directive, HostListener, inject, input} from '@angular/core';
import {TrackEventService} from '@core/services/track-event.service';

@Directive({
  selector: '[appClickTracking]',
  standalone: true
})
export class ClickTrackingDirective {
  private trackEventService = inject(TrackEventService);


  appClickTracking = input.required<string>();
  additionalContext = input<Record<string, string | number>>();

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    this.trackEventService.trackClickEvent(this.appClickTracking(), this.additionalContext() ?? {});
  }

}
