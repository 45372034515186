import {inject, Injectable, Injector} from '@angular/core';
import {environment} from '@env/environment';
import {RoutingService} from './routing.service';
import {CookiebotService} from './cookiebot.service';
import {filter, switchMap, takeUntil, tap} from 'rxjs/operators';
import {UnsubscribeService} from './unsubscribe.service';


const getOneSignalRef = () => {
  return import('onesignal-ngx').then((module) => {
    return module.OneSignal;
  })
}

@Injectable({
  providedIn: 'root'
})
export class OneSignalService {
  protected routingService = inject(RoutingService);
  protected cookiebotService = inject(CookiebotService);
  protected unsubscribeService = inject(UnsubscribeService);
  protected injector = inject(Injector);


  protected isInitialized = false;
  protected oneSignal: any;

  init() {
    if (this.isInitialized) {
      return;
    }
    // Temporary check for testing in production. Should be removed after Marketing Team has tested it.
    const params = this.routingService.getQueryParams<{activate_onesignal?: 'true'}>(true)
    if (params.activate_onesignal !== 'true') {
      return;
    }
    this.isInitialized = true;
    this.cookiebotService.cookiebotConsent.pipe(
      takeUntil(this.unsubscribeService.whileApplicationAlive()),
      filter((consent) => consent.marketing),
      // Must only inject service, if user have given consent. Otherwise the service will set a cookie
      switchMap(() => getOneSignalRef()),
      tap((OneSignal) => {
        if (this.oneSignal) {
          return;
        }
        const oneSignal = this.injector.get(OneSignal);
        (window as any).oneSignal = oneSignal;
        (window as any).OneSignal = OneSignal;
        this.oneSignal = oneSignal.init({
          appId: environment.oneSignal.appId,
        });
      })
    ).subscribe()
  }


}
