import {Component} from '@angular/core';
import {SupportPersonIconComponent} from '@core/svg-icons/support-person-icon.component';
import {NgOptimizedImage} from '@angular/common';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {faFacebook} from '@fortawesome/free-brands-svg-icons/faFacebook';
import {faInstagram} from '@fortawesome/free-brands-svg-icons/faInstagram';
import {faLinkedin} from '@fortawesome/free-brands-svg-icons/faLinkedin';

@Component({
  selector: 'app-footer-top',
  templateUrl: './footer-top.component.html',
  styleUrl: './footer-top.component.scss',
  imports: [
    SupportPersonIconComponent,
    NgOptimizedImage,
    FaIconComponent
  ]
})
export class FooterTopComponent {

  protected readonly faFacebook = faFacebook;
  protected readonly faInstagram = faInstagram;
  protected readonly faLinkedin = faLinkedin;
}
