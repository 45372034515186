<div class="container">
  <div class="d-flex flex-row items-center justify-center">
    <app-support-person-icon class="me-4"/>
    <div class="d-flex flex-column">
      <p class="help-title flex-grow-1 mb-0 font-serif">Har du brug for hjælp?</p>
      <div class="contact-info">
        Kundeservice:
        <div>
          <a href="tel:98921853">98 92 18 53</a> •
          <a href="mailto:info@supervin.dk">info&#64;supervin.dk</a>
        </div>
      </div>
      <div class="contact-info">
        Erhverv:
        <div>
          <a href="tel:81611638">81 61 16 38</a> •
          <a href="mailto:mso@supervin.dk">mso&#64;supervin.dk</a>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex flex-column align-items-center">
    <a href="https://certifikat.emaerket.dk/supervin.dk" target="_blank">
      <img class="trust-logo"
           height="56" ngSrc="https://a.storyblok.com/f/106593/250x150/185962dc36/e-maerket.png" width="95">
    </a>
    <p class="mb-0 mt-2">Sikker e-handel</p>
  </div>

  <div class="social-media-icons">
    <p class="mb-0 font-serif">Følg med backstage:</p>
    <div>
      <a href="https://www.facebook.com/supervin.dk" target="_blank">
        <fa-icon [icon]="faFacebook" size="2xl"/>
      </a>
      <a href="https://www.instagram.com/supervin.dk/" target="_blank">
        <fa-icon [icon]="faInstagram" size="2xl"/>
      </a>
      <a href="https://www.linkedin.com/company/supervin-dk/" target="_blank">
        <fa-icon [icon]="faLinkedin" size="2xl"/>
      </a>
    </div>
  </div>
</div>
