import {Component, inject, Input} from '@angular/core';
import {AlgoliaProduct} from '@core/interfaces/algolia-product';
import {environment} from '@env/environment';

import {RouterModule} from '@angular/router';
import {CurrencyPipe, NgOptimizedImage} from '@angular/common';
import {AlgoliaInsightsService} from '@core/services/algolia-insights.service';
import {RouterContextDirective} from '@core/directives/router-context.directive';

@Component({
  selector: 'app-hits-item',
  templateUrl: './hits-item.component.html',
  styleUrls: ['./hits-item.component.scss'],
  imports: [CurrencyPipe, RouterModule, NgOptimizedImage, RouterContextDirective]
})
export class HitsItemComponent {
  private algoliaInsightsService = inject(AlgoliaInsightsService);


  private _product: AlgoliaProduct;
  private _imgURL: string;

  get product(): AlgoliaProduct {
    return this._product;
  }

  @Input()
  set product(value: AlgoliaProduct) {
    this._product = value;
    this._imgURL = this.updateImgURL();
  }

  getDef(product: any) {
    return product.price_table.def > 0 ? product.price_table.def : 1;
  }

  get imgURL(): string {
    return this._imgURL;
  }

  protected updateImgURL() {
    const prefix = environment.supervin.assets;
    const path = this._product.product_image_thumb;
    return prefix + path;
  }

  get priceTableCheap(): number | null {
    const priceTable = this._product.price_table;

    if (!priceTable) {
      return null;
    }

    const pricings = priceTable.pricings;
    let def = parseInt(priceTable.def, 10);

    if (def === 0) {
      def = 1;
    }

    let cheap: any = null;

    for (const pricing of pricings) {
      const min = parseFloat(pricing.min);
      const max = parseFloat(pricing.max);
      let priceInc = pricing.inc;

      if (Object.keys(pricing.discount || {}).length > 0) {
        if (this.product.commerce_stock_bool) {
          priceInc = pricing.discount.price;
        }
      }

      if (def >= min && (def <= max || max === -1)) {
        cheap = priceInc;
      }


    }

    return parseFloat(cheap);
  }

  get priceTableBase(): number | null {
    const priceTable = this._product.price_table;

    if (!priceTable) {
      return null;
    }

    const pricings = priceTable.pricings;

    let base: any = null;

    for (const pricing of pricings) {
      const min = parseFloat(pricing.min);
      let priceInc = pricing.inc;

      if (Object.keys(pricing.discount || {}).length > 0) {
        priceInc = pricing.discount.price;
      }

      if (min === 1) {
        base = priceInc;
      }
    }

    if (this.priceTableCheap === base) {
      return null;
    }

    return parseFloat(base);

  }

  productClicked() {
    this.algoliaInsightsService.clickedOnProduct(this.product.objectID, this.product.__queryID, this.product.__position);
  }
}
