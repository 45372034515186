<div class="searchbox-wrapper">
  <input
    [placeholder]="'Indtast dit søgeord'"
    type="text"
    #input
    (keyup)="showResultsContainer($event)"
    (focus)="showResultsContainer()"
    (blur)="triggerSearchInputIsOnFocus(false)"
  />
  <div class="icons">
    @if (input.value) {
      <fa-icon (click)="resetSearch()" class="reset-icon" [icon]="faTimes"></fa-icon>
    }
    <fa-icon (click)="goToSearchPage('Click')" [icon]="faSearch" class="search-icon"></fa-icon>
  </div>
</div>

