import {Component} from '@angular/core';
import {NgOptimizedImage} from '@angular/common';

@Component({
  selector: 'app-footer-our-stores',
  templateUrl: './footer-our-stores.component.html',
  styleUrl: './footer-our-stores.component.scss',
  imports: [
    NgOptimizedImage
  ]
})
export class FooterOurStoresComponent {

}
