import {ChangeDetectionStrategy, Component, inject, Signal} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GroupVoucherService, GroupVoucherWrapper} from '@core/services/groupVoucher.service';
import {RouterModule} from '@angular/router';
import {TrackEventService} from '@core/services/track-event.service';
import {UserGroupIconComponent} from '@core/svg-icons/user-group-icon.component';


@Component({
  selector: 'app-header-middle-voucher',
  templateUrl: './header-middle-voucher.component.html',
  styleUrls: ['./header-middle-voucher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterModule, UserGroupIconComponent]
})
export class HeaderMiddleVoucherComponent {
  private modalService = inject(NgbModal);
  private voucherService = inject(GroupVoucherService);
  private trackEventService = inject(TrackEventService);

  public groupVoucher: Signal<GroupVoucherWrapper>

  constructor() {
    this.groupVoucher = this.voucherService.groupVoucherSignal;
  }

  openModal() {
    this.trackEventService.trackClickEvent('voucher_modal_open');
    import('../header-middle-voucher-modal/header-middle-voucher-modal.component').then(comp => {
      this.modalService.open(comp.HeaderMiddleVoucherModalComponent);
    });
  }

}
