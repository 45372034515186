import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  NgZone,
  OnDestroy,
  output,
  PLATFORM_ID
} from '@angular/core';
import {AlgoliaProduct} from '@core/interfaces/algolia-product';
import {AlgoliaState} from '@core/interfaces/algolia-state';
import {fromEvent, Subscription} from 'rxjs';

import {RefinementListComponent} from '../refinement-list/refinement-list.component';
import {RouterModule} from '@angular/router';
import {HitsItemComponent} from '../hits-item/hits-item.component';
import {isPlatformBrowser} from '@angular/common';
import {RefinementListItem} from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import {RouterContextDirective} from '@core/directives/router-context.directive';

@Component({
  selector: 'app-hits-container',
  templateUrl: './hits-container.component.html',
  styleUrls: ['./hits-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RefinementListComponent, RouterModule, HitsItemComponent, RouterContextDirective]
})
export class HitsContainerComponent implements AfterViewInit, OnDestroy {
  protected ngZone = inject(NgZone);
  private platformId = inject(PLATFORM_ID);


  readonly hits = input<AlgoliaProduct[]>();
  readonly state = input<AlgoliaState>();
  readonly searchQuery = input<string>();

  readonly disableResultsVisibility = output();
  private documentClickEvent: Subscription

  clickout(event: any) {
    this.disableResultsVisibility.emit();
  }

  transformAreaAndDistrictItems(items: RefinementListItem[]) {
    return items.filter(({label}) => !label.includes('Øvrige'));
  }

  getSearchValue() {
    return this.searchQuery() ?? '';
  }

  ngAfterViewInit() {
    this.initClickEvent();
  }

  ngOnDestroy() {
    this.documentClickEvent?.unsubscribe();
  }

  initClickEvent() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      this.documentClickEvent = fromEvent(window.document, 'click').subscribe(event => this.clickout(event))
    })
  }

}
