import {Component, computed, contentChild, HostBinding, input, OnInit, TemplateRef} from '@angular/core';
import {NgTemplateOutlet} from '@angular/common';
import {
  InstantSearchBaseRefinementListComponent
} from '@core/instantsearch/components/instantsearch-base-refinement-list.component';


@Component({
  selector: 'app-instantsearch-refinement-list',
  templateUrl: './instantsearch-refinement-list.component.html',
  styleUrls: ['./instantsearch-refinement-list.component.scss'],
  imports: [NgTemplateOutlet]
})
export class InstantsearchRefinementListComponent extends InstantSearchBaseRefinementListComponent implements OnInit {


  readonly showResults = input(false);

  readonly template = contentChild(TemplateRef);

  @HostBinding('style.display') get display() {
    return (this.showResults() && this.state().items.length > 0) ? 'block' : 'none';
  }

  items = computed(() => this.state().items);

  preventDefault(event: any, item: any) {
    event.stopPropagation();
    event.preventDefault();
    this.refine(event, item);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  toggleShowMore(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.state().toggleShowMore();
  }

}
