<div (click)="isPreviewVisible$.next(!isPreviewVisible$.value)" [attr.data-rr-shared]="(cartSignal().cart.itemsTotalQty || 0) <= 0"
     class="cart-small justify-content-end">
  <div class="cart-title">
    <app-cart-icon/>
    <span class="num">{{ cartSignal().cart.itemsTotalQty }}</span>
    @if (cartSignal().cart.totalPrice) {
      <div class="cart-total">
        <span>{{ cartSignal().cart.totalPrice | price | currency }}&nbsp;<span class="currency-c"> kr. </span></span>
      </div>
    } @else {
      <span class="cart-text hide-element-on-mobile">Kurv</span>
    }
  </div>
</div>
@if ((isPreviewVisibleObservable|async) && cartSignal().cart) {
  <app-cart-small-preview (showPreviewChange)="isPreviewVisible$.next($event)"
    [cart]="cartSignal().cart"
  />
}
