import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HeaderMiddleComponent} from './components/header-middle/header-middle.component';

import {HeaderTopComponent} from './components/header-top/header-top.component';
import {HeaderBottomComponent} from './components/header-bottom/header-bottom.component';
import {AsyncPipe} from '@angular/common';
import {filter, tap} from 'rxjs/operators';
import {UserService} from '@core/services/user.service';
import {RoutingService} from '@core/services/routing.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [HeaderMiddleComponent, AsyncPipe, HeaderTopComponent, HeaderBottomComponent]
})
export class HeaderComponent implements OnInit {
  protected userService = inject(UserService);
  private routingService = inject(RoutingService);


  isCollapsedSubject = new BehaviorSubject<boolean>(true);
  isCollapsed$: Observable<boolean>

  router$: Observable<any>;

  ngOnInit() {
    this.router$ = this.routingService.onRouteChange().pipe(
      filter(() => !this.isCollapsedSubject.getValue()),
      tap(() => {
        this.isCollapsedSubject.next(true);
      })
    );
    this.isCollapsed$ = this.isCollapsedSubject.asObservable();
  }

}
