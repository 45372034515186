import {Component, ElementRef, inject, OnInit, output, viewChild} from '@angular/core';
import {connectSearchBox} from 'instantsearch.js/es/connectors';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {TypedBaseWidgetComponent} from '@core/instantsearch/types-based-widget';
import {faSearch, faTimes} from '@fortawesome/free-solid-svg-icons';
import {TrackEventService} from '@core/services/track-event.service';

@Component({
  selector: 'app-searchbox',
  templateUrl: './searchbox.component.html',
  styleUrls: ['./searchbox.component.scss'],
  imports: [FontAwesomeModule]
})
export class SearchboxComponent extends TypedBaseWidgetComponent<any, any> implements OnInit {
  instantSearchInstance = inject(InstantSearchComponent);
  private trackEventService = inject(TrackEventService);


  readonly inputElement = viewChild<ElementRef<HTMLInputElement>>('input');
  readonly displaySearchContainer = output<boolean>();
  readonly searchInputIsOnFocus = output<boolean>();

  constructor() {
    super('SearchBox');
  }

  showResultsContainer(event?: KeyboardEvent) {
    const inputElement = this.inputElement();
    if (!inputElement) {
      return;
    }
    const inputValue = inputElement.nativeElement.value.trim();

    if (inputValue.length === 0) {
      this.triggerDisplaySearchContainer(false);
      this.triggerSearchInputIsOnFocus(false);
      return;
    }

    this.state().refine(inputValue);

    if (event && event.keyCode === 13) {
      this.goToSearchPage('EnterKey');
    } else if (event && event.keyCode === 27) {
      this.triggerDisplaySearchContainer(false);
      this.triggerSearchInputIsOnFocus(false);
    } else {
      this.triggerDisplaySearchContainer(true);
      this.triggerSearchInputIsOnFocus(true);
    }
  }

  resetSearch() {
    const inputElement = this.inputElement();
    if (!inputElement) {
      return;
    }
    inputElement.nativeElement.value = '';
    inputElement.nativeElement.focus();
  }

  goToSearchPage(eventName?: string) {
    const inputEl = this.inputElement()?.nativeElement;
    const value = inputEl?.value?.trim();
    if (!value) {
      return;
    }
    const query = {queryParams: {'Products[query]': value}};
    const trackingName = 'quickSearch' + (eventName ? `${eventName}` : '');
    this.trackEventService.navigateWithTracking(['/searchpage'], query, trackingName, {searchQuery: value}, true);
    this.inputElement()?.nativeElement.blur();
    this.triggerDisplaySearchContainer(false);
  }

  override ngOnInit(): void {
    this.createWidget(connectSearchBox as any, {});
    super.ngOnInit();
  }

  triggerSearchInputIsOnFocus(value: boolean) {
    this.searchInputIsOnFocus.emit(value);
  }

  triggerDisplaySearchContainer(value: boolean) {
    this.displaySearchContainer.emit(value);
  }

  protected readonly faTimes = faTimes;
  protected readonly faSearch = faSearch;
}
