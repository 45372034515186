import {Component, HostBinding, inject, TemplateRef} from '@angular/core';

import {NgbToastModule} from '@ng-bootstrap/ng-bootstrap';
import {NgTemplateOutlet} from '@angular/common';
import {ToastService} from '@core/services/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  imports: [NgbToastModule, NgTemplateOutlet]
})
export class ToastComponent {
  toastService = inject(ToastService);


  @HostBinding('class') class = 'ngb-toasts';

  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
