import {Component, input} from '@angular/core';

@Component({
  selector: 'app-suitcase-icon',
  styles: `
    :host {
      display: inline-block;
      line-height: 0;
    }
  `,
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      [attr.width]="width()"
      [attr.height]="height()"
      [attr.fill]="color()">
      <path d="M176 56l0 40 160 0 0-40c0-4.4-3.6-8-8-8L184 48c-4.4 0-8 3.6-8 8zM128 96l0-40c0-30.9 25.1-56 56-56L328 0c30.9 0 56 25.1 56 56l0 40 0 32 0 352-256 0 0-352 0-32zM64 96l32 0 0 384-32 0c-35.3 0-64-28.7-64-64L0 160c0-35.3 28.7-64 64-64zM448 480l-32 0 0-384 32 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64z"/>
    </svg>
  `,
  standalone: true
})
export class SuitcaseIconComponent {
  readonly width = input<string>('25px');
  readonly height = input<string>('27px');
  readonly color = input<string>('white');
}
