import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {RoutingService, UrlFormatted} from '@core/services/routing.service';
import {BreadcrumbService, BreadcrumbStyle} from '@core/services/breadcrumb.service';
import {Observable} from 'rxjs';

import {RouterModule} from '@angular/router';
import {AsyncPipe} from '@angular/common';
import {PageBreadcrumb} from '@core/interfaces/generated/graphql';
import {RouterContextDirective} from '@core/directives/router-context.directive';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, RouterModule, RouterContextDirective]
})
export class BreadcrumbsComponent implements OnInit {
  private routingService = inject(RoutingService);
  private breadcrumbService = inject(BreadcrumbService);


  breadcrumbs$: Observable<PageBreadcrumb[]>;
  breadcrumbStyle$: Observable<BreadcrumbStyle>;

  ngOnInit(): void {
    this.breadcrumbs$ = this.breadcrumbService.getCurrentBreadcrumbsAsync();
    this.breadcrumbStyle$ = this.breadcrumbService.getCurrentStyleAsync();
  }

  getFormattedUrl(path: string | null | undefined): UrlFormatted {
    const defaultFormattedUrl = {
      isRelative: false,
      search: {},
      fullURL: '',
      hash: undefined,
      pathname: ''
    };
    if (!path) {
      return defaultFormattedUrl
    }
    const formattedUrl = this.routingService.getUrlFormattedObjectByPath(path);
    if (formattedUrl === undefined) {
      return defaultFormattedUrl
    }
    return formattedUrl;
  }
}
