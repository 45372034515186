import {ChangeDetectionStrategy, Component} from '@angular/core';
import {NgOptimizedImage} from '@angular/common';
import {FooterTopComponent} from '@layout/components/general/footer/components/footer-top/footer-top.component';
import {
  FooterNewsletterComponent
} from '@layout/components/general/footer/components/footer-newsletter/footer-newsletter.component';
import {
  FooterOurStoresComponent
} from '@layout/components/general/footer/components/footer-our-stores/footer-our-stores.component';
import {
  FooterInfoAndLinksComponent
} from '@layout/components/general/footer/components/footer-info-and-links/footer-info-and-links.component';
import {
  FooterBottomComponent
} from '@layout/components/general/footer/components/footer-bottom/footer-bottom.component';

@Component({
  selector: 'footer[app-footer]',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgOptimizedImage, FooterTopComponent, FooterNewsletterComponent, FooterOurStoresComponent, FooterInfoAndLinksComponent, FooterBottomComponent]
})
export class FooterComponent {

}
