<div class="container">
  <div>
    <p>Her kan du betale med</p>
    <div class="cards">
      <img alt="MobilePay" height="29" ngSrc="assets/images/cards-and-deliverymethods/mobilepay.png" width="46">
      <img alt="Dankort" class="no-bg" height="29" ngSrc="assets/images/cards-and-deliverymethods/dankort.png"
           width="46">
      <img alt="Visa" class="no-bg" height="29" ngSrc="assets/images/cards-and-deliverymethods/visa.png" width="46">
      <img alt="Mastercard" height="29" ngSrc="assets/images/cards-and-deliverymethods/mastercard.png" width="46">
      <img alt="Maestro" height="29" ngSrc="assets/images/cards-and-deliverymethods/maestro.png" width="46">
      <img alt="JCB" height="29" ngSrc="assets/images/cards-and-deliverymethods/jcb.png" width="46">
      <img alt="American Express" height="29" ngSrc="assets/images/cards-and-deliverymethods/americanexpress.png"
           width="46">
      <img alt="Viabill" class="px-1" height="29" ngSrc="assets/images/cards-and-deliverymethods/viabill.png"
           width="46">
    </div>
  </div>
  <div>
    <p>Dag-til-dag levering med</p>
    <div class="delivery">
      <img alt="Xpressen" class="p-1" height="29" ngSrc="assets/images/cards-and-deliverymethods/xpressen.png"
           width="46">
      <img alt="Burd" class="px-1" height="29" ngSrc="assets/images/cards-and-deliverymethods/burd.png" width="46">
      <img alt="Danske fragtmænd" height="19" ngSrc="assets/images/cards-and-deliverymethods/dfm.png" width="62">
    </div>
  </div>
  <div>
    <p class="mb-1">Fragtpriser fra kun 19,-</p>
    <p class="mb-1">Fri fragt ved køb over 1.000,-</p>
    <p class="mb-1">Gratis afhentning i vores butikker</p>
  </div>
</div>
