@if (!isHidden()) {
  <div
  >
    <ul>
      @for (item of state().items; track item?.label) {
        <li
        >
          <a [appRouterContext]="trackEventName() ?? ''" [routerLink]="item.value">
            {{ item.label }}
          </a>
        </li>
      }
      @if (showMoreLimit() && state().canToggleShowMore) {
        <li (click)="toggleShowMore($event)" class="show-more">
          <a>{{ state().isShowingMore ? 'Vis færre' : 'Vis alle' }}</a>
        </li>
      }
    </ul>
  </div>
}
