import {Component} from '@angular/core';
import {RouterContextDirective} from '@core/directives/router-context.directive';

@Component({
  selector: 'app-footer-info-and-links',
  templateUrl: './footer-info-and-links.component.html',
  styleUrl: './footer-info-and-links.component.scss',
  imports: [
    RouterContextDirective
  ]
})
export class FooterInfoAndLinksComponent {

}
