<app-footer-top/>
<app-footer-newsletter/>
<app-footer-our-stores/>
<app-footer-info-and-links/>
<app-footer-bottom/>

<div class="footer-bottom" data-rr-shared="true">
  <div class="container">
    <p class="footer-text">SUPERVIN, HJØRRING A/S</p>
    <p class="footer-text hide-element-on-mobile">Skagensvej 201</p>
    <p class="footer-text hide-element-on-mobile">9800 Hjørring</p>
    <p class="footer-text hide-element-on-mobile"> Tlf. 98921853</p>
    <p class="footer-text">
      <span>CVR 21281573</span>&nbsp;
      <a class="hide-element-on-mobile" href="mailto:info@supervin.dk">info&#64;supervin.dk</a>
    </p>
    <a href="https://www.findsmiley.dk/23815" target="_blank">
          <span class="footer-text">
            <img ngSrc='https://a.storyblok.com/f/106593/99x32/307796f795/banner_gul_99x32.gif' height="32" style="width: 99px; height: 32px;"
                 width="99">
          </span>
    </a>
  </div>
</div>
