@if (showResults() && state().items.length > 0) {
  <div class="ais-RefinementList">
    <ul class="ais-RefinementList-list">
      <ng-content/>
      @for (item of items(); track item.value) {
        @if (template(); as templateRef) {
          <ng-container *ngTemplateOutlet="templateRef; context: {item: item}"></ng-container>
        } @else {
          <label class="ais-RefinementList-label">
            <input
              class="ais-RefinementList-checkbox"
              type="checkbox"
              value="{{item.value}}"
              (change)="preventDefault($event, item)"
              [checked]="item.isRefined"
            />
            <span class="ais-RefinementList-labelText">
              {{ item.label }}
            </span>
            <span class="ais-RefinementList-count">{{ item.count }}</span>
          </label>
        }
      }
      @if (showMoreLimit() && state().canToggleShowMore) {
        <li (click)="toggleShowMore($event)"
            class="ais-RefinementList-item">
          {{ state().isShowingMore ? 'Vis færre' : 'Vis alle' }}
        </li>
      }
    </ul>
  </div>
}
