@if (searchConfig(); as conf) {
  <app-instantsearch [attr.data-rr-shared]="!isResultsVisible()" (changeEvent)="stateChange($event)" [config]="conf">
    <app-searchbox #searchBox
      (displaySearchContainer)="displaySearchContainer($event)"
                   (searchInputIsOnFocus)="setSearchboxFocus($event)"/>
    @if (state(); as st) {
      <app-hits-container (disableResultsVisibility)="disableResultsVisibility()"
        [searchQuery]="searchBox?.inputElement()?.nativeElement?.value || ''"
        [state]="st"
        [hits]="hits()"
        [class.d-none]="!isResultsVisible()"
      />
    }
  </app-instantsearch>
}
