import {Component, HostBinding, HostListener, inject, input} from '@angular/core';
import {HierarchicalMenuItem} from 'instantsearch.js/es/connectors/hierarchical-menu/connectHierarchicalMenu';
import {InstantsearchHierarchicalMenuComponent} from '../../instantsearch-hierarchical-menu.component';


@Component({
  selector: 'a[app-instantsearch-hierarchical-menu-item]',
  templateUrl: './instantsearch-hierarchical-menu-item.component.html',
  styleUrls: ['./instantsearch-hierarchical-menu-item.component.scss'],
  standalone: true,
})
export class InstantsearchHierarchicalMenuItemComponent {
  instantsearchHierarchicalMenu = inject(InstantsearchHierarchicalMenuComponent);


  public readonly lvl = input(1);
  public readonly refine = input.required<(str: any) => void>();
  public readonly createURL = input<(str: string) => string>();
  public readonly item = input.required<HierarchicalMenuItem>();
  public readonly url = input<string>();
  public readonly fragment = input<string>();

  private _isHovering = false;

  @HostBinding('class')
  get hostClass() {
    if (this.item().isRefined) {
      return 'hovering';
    }
    return '';
  }

  @HostBinding('title') get title() {
    return this.getItemLabel(this.item());
  }

  @HostListener('mouseenter')
  mouseEnter() {
    this.isHovering = true;
  }

  @HostListener('mouseleave')
  mouseLeave() {
    this.isHovering = false;
  }

  get isHovering(): boolean {
    return this._isHovering;
  }

  set isHovering(value: boolean) {
    this._isHovering = value;

    if (this.isHovering) {
      setTimeout(() => {
        const item = this.item();
        if (this.isHovering && item && !item?.isRefined) {
          this.refine()(item.value);
        }
      }, 300);
    }
  }

  getItemLabel(item: any): string|null {
    return this.instantsearchHierarchicalMenu.getItemLabel(item)
  }
}
