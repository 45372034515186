<div (mouseleave)="setActiveSubMenuItem()" class="menu-wrapper">
  @for (column of menuStructure()?.sub_links; track column._uid) {
    <div class="menu-col">
      @for (item of column.links; track item.Name) {
        @if (getMenuType(item) === 'subMenu') {
          <div>
            <div (click)="subMenuItemClick($event, item)"
                 (mouseenter)="subMenuItemMouseEnter(item)"
                 (mouseleave)="subMenuItemMouseLeave()"
                 [class.active]="activeSubMenuItem() === item"
                 [additionalContext]="getAdditionalContext(item)"
                 [appClickTracking]="'headerSubMenu'"
                 class="sub-menu">
              <div class="menu-item">
                @if (item.Image?.filename) {
                  <img [ngSrc]="item.Image?.filename"
                       height="50"
                       width="50"/>
                }
                <span style="flex-grow: 1">{{ item.Name }}</span>
                @if (activeSubMenuItem() === item) {
                  <fa-icon class="hide-element-on-desktop" [icon]="faAngleUp"></fa-icon>
                }
                @if (activeSubMenuItem() !== item) {
                  <fa-icon class="hide-element-on-desktop" [icon]="faAngleDown"></fa-icon>
                }
                <fa-icon class="hide-element-on-mobile" [icon]="faAngleRight"></fa-icon>
              </div>
            </div>
            @if (activeSubMenuItem() === item) {
              <app-header-menu-sub-menu
                [menuStructure]="activeSubMenuItem()"
                [pathWithLabels]="getPath(item)">
              </app-header-menu-sub-menu>
            }
          </div>
        }
        @if (getMenuType(item) === 'menu') {
          @if (!isExternalURL(item.Link.url)) {
            <a
              (click)="closeMenu()"
              [additionalContext]="getAdditionalContext(item)"
              [appRouterContext]="'headerSubMenu'"
              [routerLink]="item.Link.url"
              class="menu-item">
              @if (item.Image?.filename) {
                <img [ngSrc]="item.Image?.filename"
                     height="50"
                     width="50"/>
              }
              {{ item.Name }}
            </a>
          }
          @if (isExternalURL(item.Link.url)) {
            <a
              [additionalContext]="getAdditionalContext(item, {externalLink: item.Link.url})"
              [appClickTracking]="'headerSubMenu'"
              [href]="item.Link.url"
              class="menu-item">
              @if (item.Image?.filename) {
                <img [ngSrc]="item.Image?.filename"
                     height="50"
                     width="50"/>
              }
              {{ item.Name }}
            </a>
          }
        }
      }
    </div>
  }
</div>
