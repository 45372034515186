<div class="container">
  <h4 class="font-serif">
    Vær den første til at modtage vores
    <span>bedste tilbud</span>
  </h4>
  <p class="tagline">
    Gå ikke glip af skarpe priser, spændende restpartier - eller 100 kr. i velkomstrabat til nye modtagere
  </p>
  @if (showSuccessMessage()) {
    <ngb-alert [dismissible]="false" class="mt-2 fw-normal" type="success">
      Tak for din tilmelding til vores nyhedsbrev
    </ngb-alert>
  } @else if (showErrorMessage()) {
    <ngb-alert [dismissible]="false" class="mt-2 fw-normal" type="danger">
      Der skete en fejl. Prøv igen senere
    </ngb-alert>
  }
  @if (!showSuccessMessage()) {
    <form (ngSubmit)="submitForm()" [formGroup]="newsletterForm" class="newsletter-form">
      <div class="form-floating">
        <input [formField]="newsletterForm.get('firstname')" appFormValidationClass class="form-control"
               formControlName="firstname"
               id="firstname"
               placeholder="Dit navn" type="text"
        >
        <label for="firstname">Dit navn</label>
      </div>
      <div class="form-floating">
        <input [formField]="newsletterForm.get('email')" appFormValidationClass class="form-control"
               formControlName="email"
               id="email"
               placeholder="Din e-mail" type="email"
        >
        <label for="email">Din e-mail</label>
      </div>
      <div class="d-flex align-items-center justify-content-between">
        <button class="btn btn-newsletter-submit" [disabled]="loading()">TILMELD NYHEDSBREV</button>
      </div>
    </form>
  }
  <p class="newsletter-terms mt-3">
    Ved din tilmelding til vores nyhedsbrev giver du samtykke til at modtage e-mailmarkedsføring af hele Supervins
    sortiment og arrangementer. Vi analyserer din købshistorik for at kunne sende dig relevante tilbud. For oplysning
    om,
    hvordan vi behandler de persondata, du giver os, kan du læse vores
    <span (click)="openPersonDataPolicy()" class="person-data-policy" role="button">
      persondatapolitik her
    </span>.
    Du kan til enhver tid tilpasse dine præferencer eller trække dit samtykke tilbage.
  </p>
</div>
