import {Component, inject, input, OnInit} from '@angular/core';

import {RouterModule} from '@angular/router';

import {
  InstantSearchBaseRefinementListComponent
} from '@core/instantsearch/components/instantsearch-base-refinement-list.component';
import {ALGOLIA_SEPERATOR} from '@core/services/algolia.service';
import {RouterContextDirective} from '@core/directives/router-context.directive';
import {TrackEventService} from '@core/services/track-event.service';

@Component({
  selector: 'app-refinement-list',
  templateUrl: './refinement-list.component.html',
  styleUrls: ['./refinement-list.component.scss'],
  imports: [RouterModule, RouterContextDirective]
})
export class RefinementListComponent extends InstantSearchBaseRefinementListComponent implements OnInit {
  private trackEventService = inject(TrackEventService);

  trackEventName = input<string>();

  constructor() {
    super();
  }

  override ngOnInit() {
    const transformItemsFunc = this.transformItems();
    this.customTransformItems = (items, metadata) => {
      if (transformItemsFunc) {
        items = transformItemsFunc(items, metadata);
      }
      return items.map(item => {
        const [value, label] = item.value.split(ALGOLIA_SEPERATOR);
        return {...item, value, label} as any;
      }).filter(({label}) => label);
    };
    super.ngOnInit();
  }

  toggleShowMore(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.trackEventService.trackClickEvent('show_more_refinement_list', {
      refinementList: this.attribute(),
      eventName: this.trackEventName()
    });
    this.state().toggleShowMore();
  }

}
