@for (toast of toastService.toasts; track toast.textOrTpl) {
  <ngb-toast
    [class]="toast.classname"
    [autohide]="true"
    [delay]="toast.delay || 5000"
    (hidden)="toastService.remove(toast)"
  >
    @if (isTemplate(toast)) {
      <ng-template [ngTemplateOutlet]="toast.textOrTpl"/>
    } @else {
      {{ toast.textOrTpl }}
    }
  </ngb-toast>
}
