import {Component, computed, inject, PLATFORM_ID, Signal, viewChild} from '@angular/core';
import {AnnouncekitComponent, AnnouncekitModule} from 'announcekit-angular';
import {CookiebotConsent, CookiebotService} from '@core/services/cookiebot.service';
import {isPlatformBrowser} from '@angular/common';
import {toSignal} from '@angular/core/rxjs-interop';
import {
  CookieConsentModalComponent
} from '@layout/components/modals/cookie-consent-modal/cookie-consent-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-menu-announcekit',
  imports: [
    AnnouncekitModule
  ],
  templateUrl: './menu-announcekit.component.html',
  styleUrl: './menu-announcekit.component.scss'
})
export class MenuAnnouncekitComponent {
  private cookiebotService = inject(CookiebotService);
  private platformId = inject(PLATFORM_ID);
  private modalService = inject(NgbModal);

  readonly announceKit = viewChild<AnnouncekitComponent>('announcekitComponent');

  cookiebotConsent: Signal<CookiebotConsent | undefined>;

  showAnnounceKit = computed(() => {
    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }
    return !!this.cookiebotConsent()?.marketing
  });


  constructor() {
    this.cookiebotConsent = toSignal(this.cookiebotService.cookiebotConsent.asObservable());
  }

  public announceKitClick(event: MouseEvent) {
    const announceKit = this.announceKit();
    if (announceKit) {
      event.stopPropagation();
      event.preventDefault();
      announceKit.open();
    } else {
      this.modalService.open(CookieConsentModalComponent);
    }
  }

}
