<div class="container">
  <h5 class="font-serif">Vores butikker</h5>
  <div class="stores-container">
    <div class="hide-element-on-mobile">
      <img alt="Supervin butikker i Nordjylland" height="675" ngSrc="assets/images/Butikker%20Nordjylland%202.png"
           width="922">
    </div>
    <div class="opening-hours-container">
      <div class="store-info">
        <p class="store-title font-serif">Supervin Hjørring</p>
        <p class="mb-1">Skagensvej 201, A</p>
        <p class="mb-3">9800 Hjørring</p>
        <p class="store-opening-hours-title">Åbningstider</p>
        <div class="opening-hours">
          Mandag - Fredag: <b>10:00 - 17:30</b>
        </div>
        <div class="opening-hours">
          Lørdag: <b>10:00 - 16:00</b>
        </div>
      </div>
      <div class="store-info">
        <p class="store-title font-serif">Supervin Aalborg</p>
        <p class="mb-1">Alexander Foss Gade 10</p>
        <p class="mb-3">9000 Aalborg</p>
        <p class="store-opening-hours-title">Åbningstider</p>
        <div class="opening-hours">
          Mandag - Torsdag: <b>10:00 - 17:30</b>
        </div>
        <div class="opening-hours">
          Fredag: <b>10:00 - 18:00</b>
        </div>
        <div class="opening-hours">
          Lørdag: <b>10:00 - 16:00</b>
        </div>
      </div>
    </div>
  </div>
</div>
