import {Component} from '@angular/core';
import {NgOptimizedImage} from '@angular/common';

@Component({
  selector: 'app-footer-bottom',
  templateUrl: './footer-bottom.component.html',
  styleUrl: './footer-bottom.component.scss',
  imports: [
    NgOptimizedImage
  ]
})
export class FooterBottomComponent {

}
