import {Routes} from '@angular/router';
import {userGuard} from '@core/guards/user.guard';

export const routes: Routes = [
  {
    path: 'gruppekoeb',
    loadComponent: () => import('@modules/page/components/group-buy-page/group-buy-page.component').then(m => m.GroupBuyPageComponent),
    title: 'Gruppekøb'
  },
  {
    path: 'searchpage',
    loadComponent: () => import('@modules/page/components/search-page-algolia/search-page-algolia.component').then(m => m.SearchPageAlgoliaComponent),
    title: 'Search'
  },
  {
    path: 'checkout',
    loadChildren: () => import('@modules/checkout/checkout.routing')
  },
  {
    path: 'kundeportal',
    canActivate: [userGuard],
    loadChildren: () => import('@modules/user-universe/user-universe.routing')
  },
  {
    path: 'event',
    loadChildren: () => import('@modules/tasting-event/tasting-event.routing')
  },
  {
    path: '**',
    loadComponent: () => import('@modules/page/page.component').then(m => m.PageComponent),
    title: 'Loading...'
  },
];
